import React from 'react';
import {navigate} from 'gatsby';

import SEO from "../components/seo";
import Layout from "../components/layout";

const styles = {
    height: '100%',
};

function ContactUs () {
    const [data, setValue] = React.useState({
        email: '',
        subject: '',
        message: ''
    });

    const setNewValue = event => {
        setValue({
            ...data,
            [event.target.name]: event.target.value
        });
    }

    const getInputs = ( {email, subject, message} ) => ( {email, subject, message} );

    const submitData = data => {
        return fetch(process.env.GATSBY_CONTACT_US_API_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify( data )
            }
        ).then( response => {
            if(response.ok)
                return response.json();
            return response.json();
        },
        networkError => {
            console.log(networkError.message);
        });
    };

    const handleResponseErrors = errors => {
        if(errors)
            if( window.confirm("Couldn't store contact request.\nPlease try again later.") )
                window.location.reload(false)
        else
            return;
    };

    const handleResponseData = data => {
        const success = window.confirm(`We will contact you as soon as possible.\nRequest id: ${data.id}`);
        return success ?  navigate('/') : navigate('/contact-us');
    };

    const handleMessages = response => {
        for( const messageType in response )
            window.alert( `${ response[ messageType ] }` );
    };

    const handleSubmit = event => {
        event.preventDefault();
        submitData( getInputs( data ) ).then(
            response => {
                if( response.errors )
                    return handleResponseErrors( response.errors );
                else if( response.data )
                    return handleResponseData( response.data );
                else
                    return handleMessages( response );
            }
        );
    };

return (
  <Layout>
    <SEO title="Contact Us" />
    <div className="contact-us-container">
        <div className="container">
            <div className="row justify justify-content-center">
                <div className="col-11 col-md-8 col-lg-6 col-xl-5">
                    <form method="POST" onSubmit={handleSubmit} >
                        <div className="card contact-us-card">
                            <div className="row mt-0">
                                <div className="col-md-12">
                                    <h4> Contact Us </h4>
                                    <p className="sub-title">All fields are required.</p>
                                </div>
                            </div>
                            <div className="form-group row mb-3">
                                <div className="col-md-12 mb-0">
                                    <label htmlFor="e-mail"><p className="mb-1"> Your Email </p></label>
                                    <input id="e-mail" name="email" type="email" placeholder="Enter your email" className="form-control input-box rm-border" onChange={setNewValue} value={data.firstname} />
                                </div>
                            </div>
                            <div className="form-group row mb-3">
                                <div className="col-md-12 mb-0">
                                    <label htmlFor="subject"><p className="mb-1"> Subject </p></label>
                                    <input id="subject" name="subject" type="text" placeholder="Message subject" className="form-control input-box rm-border" onChange={setNewValue} value={data.firstname} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="message"><p className="mb-1"> Message </p></label>
                                    <textarea id="message" placeholder="Enter your message" name="message" className="form-control input-box rm-border" rows="5" onChange={setNewValue} value={data.firstname} ></textarea>
                                </div>
                            </div>
                            <div className="form-group row justify-content-center mb-0">
                                <div className="col-md-12 px-3">
                                    <button type="submit" className="btn btn-block btn-primary submit-btn rm-border"> Submit </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</Layout>
);
}

export default ContactUs;
